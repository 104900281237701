<template>
  <div>
    <v-data-table
      v-show="tabelaCarregada"
      :headers="cabecalhoPorTipoContrato()"
      :items="tipoContrato.contratos"
      :options.sync="pagination"
      :server-items-length="totalPage"
      :no-data-text="$t('label.tabela_sem_conteudo')"
      :footer-props="{
        itemsPerPageOptions: [5, 10, 20],
      }">
      <template v-slot:body="{ items }">
        <tbody>
          <tr v-for="item in items" :key="item.nome">
            <td style="width: 2%; text-align: center;">
              <v-menu bottom
                v-if="contratoCancelado(item)"
                origin="center center"
                transition="scale-transition">
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    :id="'more_vert_' + item.id"
                    icon>
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    @click="detalharContrato(item.id)">
                    <v-list-item-action>
                      <v-icon>details</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $tc('label.detalhe', 2) }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    @click="verCancelamentoContrato(item)">
                    <v-list-item-action>
                      <v-icon>delete_sweep</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $tc('label.ver_cancelamento', 2) }}</v-list-item-title>
                   </v-list-item>
                   <v-list-item
                    v-if="mostrarGerarCartaParceria(item) && !isTenantEpoca"
                    @click="realizarDownloadCartaParceria(item)">
                    <v-list-item-action>
                      <v-icon>receipt</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $tc('label.gerar_carta_parceria', 1) }}</v-list-item-title>
                 </v-list-item>
                </v-list>
              </v-menu>
              <v-menu bottom
                v-else
                origin="center center"
                transition="scale-transition">
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on"
                    icon
                    text>
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-if="!canEdit || !contratoEmCadastro(item) || item.is_contrato_outra_divisao || !canEditContrato(item)"
                    @click="detalharContrato(item.id)">
                    <v-list-item-action>
                      <v-icon>details</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $tc('label.detalhe', 2) }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="contratoEmCadastro(item) && canEdit && !item.is_contrato_outra_divisao && canEditContrato(item)"
                    @click="editarContrato(item.id)">
                    <v-list-item-action>
                      <v-icon>edit</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $tc('label.editar', 1) }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-show="item.aprovadorFluxo && item.ind_possui_fluxo_aprovacao"
                    @click="abrirJustificativaAprovar(item)">
                    <v-list-item-action>
                      <v-icon>thumb_up</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $tc('label.aprovar', 1) }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-show="item.aprovadorFluxo && item.ind_possui_fluxo_aprovacao"
                    @click="abrirJustificativaAnalise(item)">
                    <v-list-item-action>
                      <v-icon>chat_bubble</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $tc('label.enviar_analise', 1) }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-show="(item.aprovadorFluxo && item.ind_possui_fluxo_aprovacao && isOpcaoReprovacaoHabilitada)
                    && exibicaoReprovarPersonalizada()"
                    @click="abrirJustificativaReprovar(item)">
                    <v-list-item-action>
                      <v-icon>thumb_down</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $tc('label.reprovar', 1) }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-show="item.ind_possui_fluxo_aprovacao &&
                     (item.status === 'REPROVADO' || item.status === 'EM_ANALISE') &&
                     item.tem_fluxo && !item.tem_fluxo_aprovado"
                    @click="abrirJustificativa(item)">
                    <v-list-item-action>
                      <v-icon>textsms</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $tc('label.justificativa', 1) }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-show="item.ind_possui_fluxo_aprovacao &&
                     (item.status === 'EM_ANALISE' && canEdit && item.solicitanteFluxo) &&
                     !item.tem_fluxo_aprovado"
                    @click="confirmarExecutarAnalise(item)">
                    <v-list-item-action>
                      <v-icon>edit</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $tc('label.executar_analise', 1) }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="mostrarAcompanhamento(item) && temFluxo(item)"
                    @click="abrirAcompanhamento(item)">
                    <v-list-item-action>
                      <v-icon>assignment_turned_in</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $tc('label.acompanhamento', 1) }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="mostrarGerarCartaParceria(item) && !isTenantEpoca"
                    @click="realizarDownloadCartaParceria(item)">
                    <v-list-item-action>
                      <v-icon>receipt</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $tc('label.gerar_carta_parceria', 1) }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="exibeCancelarContrato(item)"
                    @click="confirmarCancelarContrato(item)">
                    <v-list-item-action>
                      <v-icon>cancel</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ contratoAguardandoAprovacao(item) ? $tc('label.cancelar_fluxo', 1) : $tc('label.cancelar', 1) }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="exibeEncerrarContrato(item)"
                    @click="confirmarEncerrarContrato(item)">
                    <v-list-item-action>
                      <v-icon>event_busy</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $tc('label.encerrar', 1) }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item v-for="(botao) in botoesCustomizados"
                               :id="`${item.id}-botao-${botao.id}`"
                               :key="`${item.id}-botao-${botao.id}`"
                      v-if="botao.exibe(item)"
                      @click="botao.click(item)">
                    <v-list-item-action>
                      <v-icon>{{ botao.icon }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ botao.label }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                  v-if="canDesabilitarRenovacaoAuto && canEditContrato(item) && !item.ind_sem_verba"
                    @click="abrirModalJustificativa(item.id, item.ind_desabilita_renovacao)">
                    <v-list-item-action style="width:25px">
                      <v-icon>close_outline</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{!item.ind_desabilita_renovacao ? $tc('label.desabilitar_renovacao_automatica', 1) : $tc('label.habilitar_renovacao_automatica', 1)}}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
            <td v-for="col in colunasPorTipoContrato()" :key="col" :class="col === 'pendencia_renovacao' ? 'align-center' : ''">
              <status
                v-if="col === 'status'"
                :status="item.status"
                :tooltip="item.status">
              </status>
              <span class="mx-0" v-else-if="col === 'pendencia_renovacao'">
                <v-tooltip bottom >
                  <template v-slot:activator="{ on }">
                    <span icon class="mx-0" v-on="on" v-show="item.pendencia_renovacao">
                      <v-icon color="yellow darken-3">warning</v-icon>
                    </span>
                  </template>
                  <span>{{ $t('message.pendencia_renovacao') }}</span>
                </v-tooltip>
              </span>
              <span v-else-if="col === 'divisao'">
                <v-menu open-on-hover
                  offset-x
                  top
                  :nudge-width="300"
                  :close-on-content-click="false">
                  <template v-slot:activator="{ on }">
                    <span v-on="on">
                      {{item.divisao}}
                    </span>
                  </template>
                  <v-card class="PlanejamentoContratoListTabela__Hier">
                    <v-card-title>
                      {{$tc('label.hierarquia', 1)}}
                    </v-card-title>
                    <v-card-text>
                      <v-treeview
                        open-all
                        open-on-click
                        dense
                        :items="item.treeDivisao"
                        />
                    </v-card-text>
                  </v-card>
                </v-menu>
              </span>
              <span v-else-if="col === 'categoria'">
                <v-btn icon @click="abrirModalCategorias(item)">
                  <v-icon>visibility</v-icon>
                </v-btn>
              </span>
              <span v-else>
                {{formataValorColuna(col, item)}}
              </span>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
    <planejamento-acao-justificativa
      ref="modalJustificativa"
      :obrigatorio="justificativaObrigatoria"
      :salvarJustificativa="actionJustificativa"
      :observacao="justificativa"
      :is-contrato="true"
      :id-contrato="idContrato"
      :is-carta-parceria="isTipoContratoCartaParceria"
      :is-aprovacao="isAprovacao"
      :somenteLeitura="justificativaSomenteLeitura"
      :data-inicio-contrato="contratoSelecionado.dta_vigencia_inicio"
      :data-fim-contrato="contratoSelecionado.dta_vigencia_fim"
      :ind-substituicao-contrato="indSubstituicaoContrato"
      :titulo-modal="tituloModal"
    >
    </planejamento-acao-justificativa>

    <planejamento-contrato-list-dados-apuracoes-contrato-encerrado
      ref="modalApuracoesEncerradas"/>

    <confirm
      ref="confirmDialog"
      :persistent="true">
    </confirm>

    <confirm-encerramento-contrato
      ref="confirmEncerramentoContrato"
      :persistent="true"
      :contrato="contratoAtual"
      @agree="encerrarContrato">
    </confirm-encerramento-contrato>

    <acompanhamento
      ref="modalAcompanhamento"
      :dados-modal-acompanhamento="dadosModalAcompanhamento"
      :fluxo="statusPassos"
      :entidade="'CONTRATO'"
    >
    </acompanhamento>
    <detalhes-cancelamento
      ref="modalDetalhesCancelamento"
      :dados-usuario-cancelamento="dadosUsuarioCancelamento"
      :entidade="'ACAO'">
    </detalhes-cancelamento>
    <dialog-justificativa
      ref="dialogJustificativa"
      @confirmar="confirmarJustificativa">
    </dialog-justificativa>
    <dialog-categoria-contrato
      ref="dialogCategoriaContrato">
    </dialog-categoria-contrato>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { generateComputed } from '@/common/functions/roles-computed-generator';
import { buscarContratos } from '@/common/resources/planejamento-contrato-listagem';
import { getMoney } from '@/common/functions/helpers';
import { skipLoading, forceLoading, forceHideLoading } from '@/common/functions/loading';
import exportacaoCartaParceria from '@/common/functions/downloads';
import basePath from '@/common/functions/api-resource';
import Status from '@/shared-components/Status';
import Confirm from '@/shared-components/dialog/Confirm';
import DetalhesCancelamento from '@/shared-components/DetalhesCancelamento';
import Acompanhamento from '@/shared-components/workflow/Acompanhamento';
import DialogJustificativa from '@/shared-components/dialog/DialogJustificativa';
import ConfirmEncerramentoContrato from '@/shared-components/ConfirmEncerramentoContrato';
import PlanejamentoAcaoJustificativa from '@/spa/planejamento-acao/PlanejamentoAcaoJustificativa';
import BotoesDadosTabelaMixin from '@/spa/planejamento-contrato/list/BotoesDadosTabelaMixin';
import PlanejamentoContratoListDadosApuracoesContratoEncerrado from '@/spa/planejamento-contrato/list/PlanejamentoContratoListDadosApuracoesContratoEncerrado';
import DialogCategoriaContrato from './DialogCategoriaContrato';

export default {
  components: {
    Status,
    Confirm,
    ConfirmEncerramentoContrato,
    PlanejamentoAcaoJustificativa,
    Acompanhamento,
    PlanejamentoContratoListDadosApuracoesContratoEncerrado,
    DetalhesCancelamento,
    DialogJustificativa,
    DialogCategoriaContrato,
  },
  mixins: [BotoesDadosTabelaMixin],
  computed: {
    ...mapGetters('roles', [
      'getTenantId',
    ]),
    ...mapGetters('metadados', [
      'getContratoMetadado',
    ]),
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    ...generateComputed('CONTRATO', [
      'canEdit',
    ]),
    ...mapGetters('usuario', [
      'usuarioLogado',
    ]),
    isTenantEpoca() {
      return this.getTenantId === 'epoca';
    },
    canCancelarContrato() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el === 'CANCELAR_CONTRATO').length;
    },
    canEncerrarContrato() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el === 'ENCERRAR_CONTRATO').length;
    },
    canCancelarFluxo() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el === 'CANCELAR_FLUXO_APROVACAO').length;
    },
    canDesabilitarRenovacaoAuto() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el === 'CONTRATO_DESABILITAR_RENOVACAO_AUTO').length;
    },
    metadadosContrato() {
      if (this.getContratoMetadado && this.getContratoMetadado.mapaEntidades) {
        return this.getContratoMetadado;
      }
      return undefined;
    },
    isTipoContratoCartaParceria() {
      if (this.contratoSelecionado && this.contratoSelecionado.is_carta_parceria) {
        return true;
      }
      return false;
    },
    idContrato() {
      if (this.contratoSelecionado && this.contratoSelecionado.id) {
        return this.contratoSelecionado.id;
      }
      return null;
    },
    indPodeCadastrarContratoSamsung() {
      return !!this.getAllRoles.filter((el) => el.startsWith('CONTRATO_SAMSUNG_CRUD')).length
        && this.canEdit;
    },
  },
  props: {
    tipoContrato: Object,
    ordenacao: Array,
    filtros: Object,
    agrupamento: String,
  },
  watch: {
    pagination: {
      handler(pagination) {
        if (this.mesmaPagina(pagination, this.lastPagination)) {
          return;
        }
        this.lastPagination = pagination;
        this.buscarContratos();
      },
      deep: true,
    },
    filtros() {
      this.buscarContratos();
    },
  },
  data() {
    return {
      workflowContratoResource: this.$api.workflowContrato(this.$resource),
      planejamentoContratoResource: this.$api.planejamentoContratoCadastro(this.$resource),
      planejamentoContratoCarregamentoResource: this.$api.planejamentoContratoListagem(this.$resource),

      pagination: {
        rowsPerPage: 10,
      },
      totalPage: 0,
      lastPagination: {},
      indSubstituicaoContrato: false,
      dadosUsuarioCancelamento: {},
      isOpcaoReprovacaoHabilitada: false,
      dadosModalAcompanhamento: {},
      cabecalhoFixo: [
        {
          text: this.$tc('label.descricao', 1),
          value: 'descricao',
          sortable: false,
          class: 'PlanejamentoContratoListTabela__ColunaGrande',
        },
        {
          text: this.$tc('label.periodo', 1),
          value: 'periodo',
          sortable: false,
          class: 'PlanejamentoContratoListTabela__ColunaGrande',
        },
        { text: this.$tc('label.planejado', 1), value: 'planejado', sortable: false },
        { text: this.$tc('label.comprometido', 1), value: 'comprometido', sortable: false },
        { text: this.$tc('label.consumido', 1), value: 'consumido', sortable: false },
        { text: this.$tc('label.reservado', 1), value: 'reservado', sortable: false },
        { text: this.$tc('label.adendo', 1), value: 'adendo', sortable: false },
        { text: this.$tc('label.reducao', 1), value: 'reducao', sortable: false },
        { text: this.$tc('label.remanescente', 1), value: 'remanescente', sortable: false },
        { text: this.$tc('label.apurado', 1), value: 'apurado', sortable: false },
        { text: this.$tc('label.data_inicio', 1), value: 'data_inicio', sortable: false },
        { text: this.$tc('label.data_fim', 1), value: 'data_fim', sortable: false },
        { text: this.$tc('label.saldo', 1), value: 'saldo', sortable: false },
        { text: this.$tc('label.valor_consumido', 1), value: 'valor_consumido', sortable: false },
        { text: this.$tc('label.periodo_apuracao', 1), value: 'periodo_apuracao', sortable: false },
        { text: this.$tc('label.fornecedor', 1), value: 'fornecedor', sortable: false },
        { text: this.$tc('label.fornecedor_pagador', 1), value: 'fornecedor_pagador', sortable: false },
        { text: this.$tc('label.campo_cliente', 1), value: 'campo_cliente', sortable: false },
        { text: this.$tc('label.cliente', 1), value: 'cliente', sortable: false },
        { text: this.$tc('label.holding', 1), value: 'holding', sortable: false },
        { text: this.$tc('label.produto', 1), value: 'produto', sortable: false },
        { text: this.$tc('label.tipo_pagamento', 1), value: 'tipo_pagamento', sortable: false },
        {
          text: this.$tc('label.acoes', 2), value: 'acoes', sortable: false, width: '2%', align: 'center',
        },
        { text: this.$tc('label.unidade_negocio', 1), value: 'unidadenegocio', sortable: false },
        { text: this.$tc('label.grupo_fornecedor', 1), value: 'grupo_fornecedor', sortable: false },
        { text: this.$tc('label.divisao', 1), value: 'divisao', sortable: false },
        { text: this.$tc('label.periodo_planejamento', 1), value: 'periodo_planejamento', sortable: false },
        { text: this.$tc('label.calculo_apuracao', 1), value: 'calculo_apuracao', sortable: false },
        { text: this.$tc('label.base_calculo', 1), value: 'base_calculo', sortable: false },
        { text: this.$tc('label.forma_bonificacao', 1), value: 'forma_bonificacao', sortable: false },
        { text: this.$tc('label.calculo_bonificacao', 1), value: 'calculo_bonificacao', sortable: false },
        {
          text: this.$tc('label.valor', 1),
          value: 'valor',
          sortable: false,
          class: 'PlanejamentoContratoListTabela__ColunaMedia',
        },
        { text: this.$tc('label.status', 1), value: 'status', sortable: false },
        { text: this.$tc('label.id_contrato', 1), value: 'id_contrato', sortable: false },
        { text: this.$tc('label.pendencia_renovacao', 1), value: 'pendencia_renovacao', sortable: false },
        { text: this.$tc('label.tipo_contrato', 1), value: 'tipo_contrato', sortable: false },
      ],

      justificativaObrigatoria: false,
      actionJustificativa() {},
      contratoSelecionado: {},
      justificativa: '',
      justificativaSomenteLeitura: false,
      statusPassos: {},
      listaApuracoesContratoEncerrado: [],
      isAprovacao: false,
      tituloModal: '',
      contratoAtual: {},
      idContratoDesabilitar: null,
      indDesabilitaRenovacao: false,
      tabelaCarregada: false,
    };
  },
  methods: {
    getMoney,
    mesmaPagina(p1, p2) {
      return p1.page === p2.page && p1.itemsPerPage === p2.itemsPerPage;
    },
    verificaSeOpcaoReprovacaoContratoHabilitada() {
      this.planejamentoContratoResource.verificaSeOpcaoReprovacaoContratoHabilitada()
        .then((res) => {
          this.isOpcaoReprovacaoHabilitada = res.data;
        });
    },
    buscarContratos() {
      const params = {
        ...this.filtros,
        numeroPagina: this.pagination.page,
        tamanhoPagina: this.pagination.itemsPerPage,
      };

      if (this.tipoContrato.id) {
        params.id_contrato_tipo = this.tipoContrato.id;
      }

      if (this.tipoContrato.idAgrupamento) {
        params.id_agrupamento = this.tipoContrato.idAgrupamento;
        params.agrupamento = this.agrupamento;
      }

      buscarContratos(params, this.$resource)
        .then((res) => {
          this.totalPage = res.data.quantidadeRegistrosPagina;
          this.tipoContrato.contratos = [...res.data.resposta];
          this.tabelaCarregada = true;
          forceLoading();
          const promises = [];
          this.tipoContrato.contratos.forEach((c) => {
            promises.push(this.verificarUsuarioAprovador(c));
            promises.push(this.verificarUsuarioSolicitante(c));
            promises.push(this.buscarStatusFluxo(c));
            promises.push(this.montaArvoreInvertidaDivisao(c));
          });
          Promise.all(promises)
            .then(() => {
              setTimeout(() => this.$forceUpdate());
              forceHideLoading();
            })
            .catch((err) => {
              forceHideLoading();
              this.$error(this, err);
            });
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    manterFiltro() {
      this.$emit('manter_filtro');
    },
    verCancelamentoContrato(item) {
      const idContrato = item.id_contrato;
      this.planejamentoContratoCarregamentoResource.buscaUsuarioCancelamentoContrato({ idContrato }, { idContrato })
        .then((res) => {
          this.dadosUsuarioCancelamento = res.body;
          this.$refs.modalDetalhesCancelamento.open();
        })
        .catch((err) => {
          this.$toast(this.$t(err));
        });
    },
    montaArvoreInvertidaDivisao(contrato) {
      const { hier } = contrato;
      if (!hier || hier.length === 0) {
        return;
      }

      const itemHier = (name, children) => ({ name, children });

      const converteArvore = (children) => {
        children = children.map((c) => {
          const item = itemHier(c.nome, converteArvore(c.filhos));
          if (item.children.length === 0) {
            item.disabled = true;
          }
          return item;
        });
        return children;
      };

      const tree = hier.map((h) => {
        const base = itemHier(h.nome, h.filhos);
        base.children = converteArvore(base.children);
        return base;
      });

      contrato.treeDivisao = tree;
    },
    contratoAprovado(contrato) {
      const { status } = contrato;
      return status === 'APROVADO';
    },
    contratoReprovado(contrato) {
      const { status } = contrato;
      return status === 'REPROVADO';
    },
    contratoEmCadastro(contrato) {
      const { status } = contrato;
      return status === 'EM_CADASTRO';
    },
    contratoAguardandoAprovacao(contrato) {
      const { status } = contrato;
      return status === 'AGUARDANDO_APROVACAO';
    },
    contratoEmAnalise(contrato) {
      const { status } = contrato;
      return status === 'EM_ANALISE';
    },
    contratoCancelado(contrato) {
      const { status } = contrato;
      return status === 'CANCELADO';
    },
    editarContrato(idContrato) {
      this.$router.push({ name: 'editarContrato', params: { idContrato } });
    },
    detalharContrato(idContrato) {
      this.$router.push({ name: 'detalharContrato', params: { idContrato, from: 'planejamento-contrato' } });
    },
    cabecalhoPorTipoContrato() {
      const { tipoContrato } = this;

      if (tipoContrato && tipoContrato.cabecalho && tipoContrato.nome !== 'Todos') return tipoContrato.cabecalho;
      const ordenacao = this.colunasPorTipoContrato(),
        cabecalho = [];

      ordenacao.forEach((col) => {
        let isColFixa = false;
        for (let i = 0; i < this.cabecalhoFixo.length; i += 1) {
          const defCol = this.cabecalhoFixo[i];
          isColFixa = defCol.value === col;
          if (isColFixa) {
            cabecalho.push(defCol);
            break;
          }
        }
        if (!isColFixa) {
          cabecalho.push({
            text: this.tituloCampoDinamico(col),
            value: col,
            sortable: false,
          });
        }
      }, this);

      const colAcoes = this.cabecalhoFixo.filter((c) => c.value === 'acoes')[0];
      tipoContrato.cabecalho = [colAcoes, ...cabecalho];

      return tipoContrato.cabecalho;
    },
    colunasPorTipoContrato() {
      return this.ordenacao;
    },
    tituloCampoDinamico(nomeCampo) {
      const dependencia = this.getDepenciaMetadadosPorNome(nomeCampo);
      if (dependencia != null) {
        return dependencia.entidadeEstrangeira;
      }
      const campoDinamico = this.getCampoDinamicoPorNome(nomeCampo);
      if (!campoDinamico) {
        $logger.log(nomeCampo);
      }
      return campoDinamico.nomCampo;
    },
    formataValorColuna(nomeColuna, item) {
      const coluna = this.cabecalhoFixo.filter((c) => c.value === nomeColuna)[0],
        colunaFixa = coluna != null;

      if (colunaFixa) {
        const chave = coluna.exibicao || coluna.value;
        return this.ehCampoDinheiro(chave)
          ? this.getMoney(item[chave])
          : item[chave];
      }

      if (this.ehDependenciaExtensao(nomeColuna)) {
        return this.formataValorExtensao(nomeColuna, item);
      }

      // neste caso é um campo dinamico
      // TODO: implementar tratativa de formatador(?)
      return item[nomeColuna];
    },
    ehCampoDinheiro(coluna) {
      const camposDinheiro = ['valor_consumido', 'saldo', 'valor'];
      return camposDinheiro.indexOf(coluna) >= 0;
    },
    formataValorExtensao(coluna, item) {
      const dependencia = this.getDepenciaMetadadosPorNome(coluna),
        valorConcatenado = item[coluna];

      if (!valorConcatenado) return '';

      if (dependencia.isManyToMany) {
        return valorConcatenado.split(',')
          .map((p) => this.extraiNomeExtensao(p))
          .join(', ');
      }

      return this.extraiNomeExtensao(valorConcatenado);
    },
    extraiNomeExtensao(valorConcatenado) {
      const valores = valorConcatenado.split('|'),
        nomExtensao = valores[valores.length - 1];
      return nomExtensao;
    },
    ehDependenciaExtensao(nomeCampo) {
      return this.getDepenciaMetadadosPorNome(nomeCampo) != null;
    },
    getDepenciaMetadadosPorNome(nomeCampo) {
      const dependencias = this.metadadosContrato.mapaEntidades,
        dependencia = dependencias[nomeCampo];
      return dependencia;
    },
    getCampoDinamicoPorNome(nomeCampo) {
      const campos = this.metadadosContrato.mapaCamposDinamicos,
        campo = campos[nomeCampo];
      return campo;
    },
    abrirJustificativaAprovar(item) {
      this.tituloModal = this.$t('title.aprovar_anexo_comercial');
      this.justificativaObrigatoria = false;
      this.actionJustificativa = this.aprovar;
      this.justificativa = '';
      this.justificativaSomenteLeitura = false;
      this.contratoSelecionado = item;
      this.isAprovacao = true;
      this.buscarInformacoesFluxo(item.id_contrato);
    },

    buscarInformacoesFluxo(idContrato) {
      this.workflowContratoResource.status({ idContrato })
        .then((response) => {
          this.idPassoAtual = response.body.passoAtual.id;
          this.buscaIndSubstituicaoContrato(this.idPassoAtual);
        });
    },
    buscaIndSubstituicaoContrato(idPassoAtual) {
      this.workflowContratoResource.buscaFlagSubstituicaoContrato({ idPassoAtual })
        .then((response) => {
          this.indSubstituicaoContrato = response.body;
          setTimeout(() => this.$refs.modalJustificativa.open());
        });
    },
    aprovar(justificativa) {
      const idContrato = this.contratoSelecionado.id;
      this.workflowContratoResource.aprovarPasso({ idContrato },
        { observacao: justificativa })
        .then(() => {
          this.$emit('acao_aprovada');
          this.$toast(this.$t('message.contrato_aprovado'));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    abrirJustificativaReprovar(item) {
      this.tituloModal = this.$t('label.reprovar_anexo_comercial');
      this.justificativaObrigatoria = true;
      this.actionJustificativa = this.reprovar;
      this.justificativa = '';
      this.justificativaSomenteLeitura = false;
      this.contratoSelecionado = item;
      this.isAprovacao = false;
      setTimeout(() => this.$refs.modalJustificativa.open());
    },
    reprovar(justificativa) {
      const idContrato = this.contratoSelecionado.id;
      this.workflowContratoResource.reprovar({ idContrato },
        { observacao: justificativa })
        .then(() => {
          this.contratoSelecionado.status = 'REPROVADO';
          this.buscarStatusFluxo(this.contratoSelecionado);
          this.contratoSelecionado.aprovadorFluxo = false;
          this.$toast(this.$t('message.contrato_reprovado'));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    confirmarCancelarContrato(contrato) {
      if (this.contratoAguardandoAprovacao(contrato)) {
        this.abrirJustificativaCancelarFluxo(contrato);
      } else {
        this.abrirJustificativaCancelarContrato(contrato);
      }
    },
    abrirJustificativaCancelarFluxo(contrato) {
      this.tituloModal = this.$t('title.cancelar_fluxo');
      this.justificativaSomenteLeitura = false;
      this.justificativaObrigatoria = true;
      this.actionJustificativa = this.cancelarContrato;
      this.isAprovacao = false;
      this.contratoSelecionado = contrato;
      this.$refs.modalJustificativa.open();
    },
    abrirJustificativaCancelarContrato(contrato) {
      this.tituloModal = this.$t('title.cancelar_contrato');
      this.justificativaSomenteLeitura = false;
      this.justificativaObrigatoria = true;
      this.actionJustificativa = this.cancelarContrato;
      this.isAprovacao = false;
      this.contratoSelecionado = contrato;
      this.$refs.modalJustificativa.open();
    },
    confirmarEncerrarContrato(contrato) {
      this.contratoAtual = contrato;
      this.$refs.confirmEncerramentoContrato.open();
    },
    cancelarContrato(contratoOuJustificativa, justificativaNaoObrigatorio = false) {
      const contrato = justificativaNaoObrigatorio ? contratoOuJustificativa : this.contratoSelecionado;
      if (this.contratoEmCadastro(contrato)) {
        this.cancelarContratoEmCadastro(contrato, contratoOuJustificativa);
      } else {
        this.cancelarContratoComFluxo(contrato, contratoOuJustificativa);
      }
    },
    cancelarContratoEmCadastro(contrato, justificativa) {
      const idContrato = contrato.id;
      this.planejamentoContratoResource.cancelarContrato({ idContrato }, { justificativa })
        .then(() => {
          this.buscarContratos();
          this.$toast(this.$t('message.contrato_cancelado'));
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    cancelarContratoComFluxo(contrato, justificativa) {
      const idContrato = contrato.id;
      this.workflowContratoResource.cancelar({ idContrato }, { observacao: justificativa })
        .then(() => {
          this.buscarContratos();
          if (contrato.tem_fluxo_aprovado) {
            this.$toast(this.$t('message.contrato_cancelado'));
          } else {
            this.$toast(this.$t('message.fluxo_cancelado_sucesso'));
          }
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    encerrarContrato(contrato) {
      const param = {
        idContrato: contrato.id,
        justificativa: contrato.justificativa,
      };
      this.planejamentoContratoResource.encerrarContrato(param, param)
        .then(() => {
          this.$refs.modalApuracoesEncerradas.buscarApuracoesEncerradas(contrato);
          this.buscarContratos();
          this.$toast(this.$t('message.contrato_encerrado'));
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    abrirJustificativaAnalise(item) {
      this.tituloModal = this.$t('title.enviar_para_analise');
      this.justificativaObrigatoria = true;
      this.actionJustificativa = this.enviarAnalise;
      this.justificativa = '';
      this.justificativaSomenteLeitura = false;
      this.contratoSelecionado = item;
      this.isAprovacao = false;
      setTimeout(() => this.$refs.modalJustificativa.open());
    },
    enviarAnalise(justificativa) {
      const idContrato = this.contratoSelecionado.id;
      this.workflowContratoResource.enviarAnalise({ idContrato },
        { observacao: justificativa })
        .then(() => {
          this.contratoSelecionado.status = 'EM_ANALISE';
          this.buscarStatusFluxo(this.contratoSelecionado);
          this.contratoSelecionado.aprovadorFluxo = false;
          this.$toast(this.$t('message.contrato_enviado_analise'));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    verificarUsuarioAprovador(contrato) {
      if (contrato.status === 'AGUARDANDO_APROVACAO' && !contrato.is_contrato_outra_divisao) {
        const idContrato = contrato.id;
        this.workflowContratoResource.aprovador({ idContrato })
          .then((response) => {
            if (response.data.usuarioHabilitado) {
              contrato.aprovadorFluxo = true;
              this.$forceUpdate();
            }
          });
      }
    },
    verificarUsuarioSolicitante(contrato) {
      if (!contrato.is_contrato_outra_divisao && (
        contrato.status === 'EM_CADASTRO'
          || contrato.status === 'AGUARDANDO_APROVACAO'
          || contrato.status === 'EM_ANALISE'
          || contrato.status === 'REPROVADO'
          || this.permiteCancelarContrato(contrato))) {
        const idContrato = contrato.id;
        skipLoading();
        this.workflowContratoResource.solicitante({ idContrato })
          .then((response) => {
            contrato.solicitanteFluxo = response.data.usuarioHabilitado;
            this.$forceUpdate();
          });
      }
    },
    buscarStatusFluxo(contrato) {
      if ((contrato.status === 'REPROVADO' || contrato.status === 'EM_ANALISE')
        && contrato.ind_possui_fluxo_aprovacao && contrato.tem_fluxo
        && !contrato.tem_fluxo_aprovado) {
        const idContrato = contrato.id;
        this.workflowContratoResource.status({ idContrato })
          .then((response) => {
            contrato.fluxo = response.data;
          })
          .catch((err) => {
            this.$error(this, err);
          });
      }
    },
    abrirJustificativa(contrato) {
      this.justificativa = contrato.fluxo.passoAtual.observacao;
      this.justificativaSomenteLeitura = true;
      this.isAprovacao = false;
      setTimeout(() => this.$refs.modalJustificativa.open());
    },
    confirmarExecutarAnalise(contrato) {
      const content = this.$t('message.deseja_executar_analise_contrato');
      setTimeout(() => this.$refs.confirmDialog
        .openWithParams(
          this.$t('title.atencao'),
          content,
          (ok) => {
            if (ok) {
              this.executarAnalise(contrato);
            }
          },
        ));
    },
    executarAnalise(contrato) {
      const idContrato = contrato.id;
      this.planejamentoContratoResource.executarAnalise({ idContrato }, { idContrato })
        .then(() => {
          this.$router.push({ name: 'editarContrato', params: { idContrato } });
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    mostrarAcompanhamento(item) {
      return item.status !== 'EM_CADASTRO' && item.status !== 'CANCELADO';
    },
    mostrarGerarCartaParceria(item) {
      return ['EM_CADASTRO', 'AGUARDANDO_APROVACAO', 'APROVADO', 'EM_ANALISE', 'REPROVADO', 'CANCELADO', 'ENCERRADO'].includes(item.status)
        && item.is_carta_parceria;
    },
    temFluxo(item) {
      return item.ind_possui_fluxo_aprovacao && item.tem_fluxo;
    },
    abrirAcompanhamento(item) {
      const idContrato = item.id;
      this.dadosModalAcompanhamento = {
        idContrato,
      };
      this.workflowContratoResource
        .existeFluxoCadastrado({ idContrato })
        .then((res) => {
          const existeFluxo = res.data;
          if (!existeFluxo) {
            const msg = this.$t('message.contrato_finalizado_sem_fluxo');
            this.$toast(msg);
            return;
          }
          this.exibeModalAcompanhamento(idContrato);
        });
    },
    realizarDownloadCartaParceria(item) {
      const getBasePath = basePath('job', 'contrato/carta-parceria');
      const servico = 'gerar-carta-parceria';
      const resource = exportacaoCartaParceria(this.$http);
      const idContrato = item.id;
      resource.download(getBasePath, idContrato, servico)
        .then(() => {
          this.$toast(this.$t('message.carta_parceria_gerada_sucesso'));
        })
        .catch(() => {
          this.$toast(this.$t('message.carta_parceria_gerada_erro'));
        });
    },
    exibeModalAcompanhamento(idContrato) {
      this.workflowContratoResource.statusPasso({ idContrato }, { idContrato })
        .then((res) => {
          this.statusPassos = res.data;
          if (this.statusPassos.passos) {
            const passo = this.statusPassos.passos.find((e) => e.statusPasso === 'AGUARDANDO_APROVACAO');
            if (passo) {
              passo.isProximoPasso = true;
            }
          }
          setTimeout(() => this.$refs.modalAcompanhamento.open());
        })
        .catch((err) => {
          this.$toast(this.$t(err));
        });
    },
    exibeCancelarContrato(item) {
      if (this.canEdit && !item.is_contrato_outra_divisao && this.canEditContrato(item)
        && (this.contratoEmCadastro(item) || (this.canCancelarFluxo && this.contratoAguardandoAprovacao(item)))) {
        return true;
      }
      return this.canEdit && !item.is_contrato_outra_divisao && this.canEditContrato(item)
        && this.permiteCancelarContrato(item) && this.contratoEmCadastro(item);
    },
    exibeEncerrarContrato(item) {
      return this.canEdit && this.contratoAprovado(item)
        && this.canEncerrarContrato
        && !item.is_contrato_outra_divisao
        && this.canEditContrato(item);
    },
    permiteCancelarContrato(contrato) {
      return this.canCancelarContrato && contrato.solicitanteFluxo;
    },
    abrirModalJustificativa(idContrato, indDesabilitaRenovacao) {
      this.idContratoDesabilitar = idContrato;
      if (indDesabilitaRenovacao === false) {
        this.indDesabilitaRenovacao = true;
      } else {
        this.indDesabilitaRenovacao = false;
      }
      this.$refs.dialogJustificativa.open();
    },
    confirmarJustificativa(justificativa) {
      const param = {
        idContrato: this.idContratoDesabilitar,
      };
      param.justificativa = justificativa;
      param.indDesabilitaRenovacao = this.indDesabilitaRenovacao;
      this.planejamentoContratoResource.alterarContratoRenovacao(param, param)
        .then(() => {
          this.buscarContratos();
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
      this.$forceUpdate();
    },
    abrirModalCategorias(item) {
      this.$refs.dialogCategoriaContrato.open(item.id);
    },
    canEditContrato(contrato) {
      return !contrato.carta_parceria_samsung || (contrato.carta_parceria_samsung && this.indPodeCadastrarContratoSamsung);
    },
  },
  mounted() {
    this.verificaSeOpcaoReprovacaoContratoHabilitada();
  },
};
</script>
<style>
.PlanejamentoContratoListTabela__ColunaGrande {
  min-width: 210px;
}
.PlanejamentoContratoListTabela__ColunaMedia {
  min-width: 120px;
}
.PlanejamentoContratoListTabela__Hier {
  color: black !important;
}
</style>

<template>
  <div>
    <v-dialog v-model="dialogApuracoesContrato" v-if="dialogApuracoesContrato" max-width="800px" style="max-height: 400px">
      <v-card class="mr-1 mt-1 mb-1">
        <v-card-title>
          <h3 class="headline mb-0">{{ $t('title.contrato_encerrado',
            {
              idContrato: contratoSelecionado.id,
              descricaoContrato: contratoSelecionado.descricao,
            }
          ) }}</h3>
        </v-card-title>
        <v-card-text>
          <h2>{{ $tc('label.apuracao_feita', 2) }}</h2>
          <v-data-table
            :headers="headers"
            :items="listaApuracoesContratoEncerrado"
            :options.sync="pagination"
            :server-items-length="totalPage"
            :no-data-text="$t('label.tabela_sem_conteudo')"
            :footer-props="{
              itemsPerPageOptions: [5, 10, 20, 30],
            }">
            <template v-slot:item.ano_mes_apuracao="{ item }">
              {{ item.anoMesApuracaoFormatado }}
            </template>
            <template v-slot:item.vlr_apuracao="{ item }">
              {{ getMoney(item.vlrApuracao, 0) }}
            </template>
          </v-data-table>
          <h2>{{ $tc('label.total_recebido', 1) }}: {{ getMoney(itemTotalizador.vlrRecebimento, 0) }}</h2>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click.native="close">{{ $t('label.fechar') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { getMoney } from '@/common/functions/helpers';

export default {
  name: 'PlanejamentoContratoListDadosApuracoesContratoEncerrado',
  components: {
  },
  props: {
  },
  data() {
    return {
      planejamentoContratoResource: this.$api.planejamentoContratoCadastro(this.$resource),
      dialogApuracoesContrato: false,
      contratoSelecionado: null,
      itemTotalizador: {},
      listaApuracoesContratoEncerrado: [],
      totalPage: 0,
      pagination: {
        itemsPerPage: 10,
        page: 1,
      },
      headers: [
        {
          text: this.$tc('label.mes_ano', 1), value: 'ano_mes_apuracao', width: '50%', align: 'center', sortable: false,
        },
        {
          text: this.$tc('label.valor', 1), value: 'vlr_apuracao', width: '50%', align: 'center', sortable: false,
        },
      ],
    };
  },
  watch: {
    pagination: {
      handler() {
        if (this.contratoSelecionado) {
          this.buscarApuracoesEncerradas(this.contratoSelecionado);
        }
      },
      deep: true,
    },
  },
  methods: {
    getMoney,
    open() {
      this.dialogApuracoesContrato = true;
    },
    close() {
      this.dialogApuracoesContrato = false;
    },
    buscarApuracoesEncerradas(contrato) {
      this.contratoSelecionado = contrato;
      const params = {
        idContrato: contrato.id,
        tamanhoPagina: this.pagination.itemsPerPage,
        numeroPagina: this.pagination.page,
      };
      this.planejamentoContratoResource.buscarValoresApuracoesContrato(params)
        .then((res) => {
          if (res.data && res.data.resposta.length > 1) {
            const itemTotalizador = res.data.resposta[0];
            this.itemTotalizador = itemTotalizador;
            this.totalPage = res.data.quantidadeRegistrosPagina;
            this.listaApuracoesContratoEncerrado = res.data.resposta.slice(1, res.data.length);
            this.open();
          }
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
  },
};
</script>

<template>
  <div>
    <v-card class="PlanejamentoContratoListDados">
      <v-card-title class="mb-3">
        <div class="title">{{ $tc('title.contrato', 2) }}</div>
        <v-col v-if="canAlterarVencimentoContrato" class="text-right">
          <div>
            <v-btn color="primary" @click="redirecionarAlteracaoPrazoVencimento" >
              <v-icon>event</v-icon>
              {{ $tc('label.alterar_prazo_vencimento') }}
            </v-btn>
          </div>
        </v-col>
        <planejamento-contrato-list-dados-agrupamento
          ref="agrupamento"
          @PlanejamentoContratoListDadosAgrupamento_Alterado="alterarAgrupamento"/>
      </v-card-title>
      <v-expansion-panels
        accordion
        focusable
        v-if="!iniciandoPainel && !exibirTodosContratos">
        <span class="my-3 py-3" v-show="!tiposContrato || !tiposContrato.length">{{ $tc('label.tabela_sem_conteudo') }}</span>
        <v-expansion-panel
          v-for="(tipoContrato, index) in tiposContrato"
          :key="index">
          <v-expansion-panel-header>
            {{ tituloDoPainel(tipoContrato) }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <planejamento-contrato-list-dados-tabela
              :tipo-contrato="tipoContrato"
              :ordenacao="parametrizacaoContrato[tipoContrato.id]"
              :filtros="filtrosAplicados"
              :agrupamento="filtrosAplicados.agrupamento"
              :ref="`tabela-${tipoContrato.id}`"
              @acao_aprovada="aplicaFiltros(null, true)"
              />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <planejamento-contrato-list-dados-tabela
        v-else-if="!iniciandoPainel && exibirTodosContratos"
        :tipo-contrato="tipoContratoTodos"
        :ordenacao="camposTodosTiposContratos"
        :filtros="filtrosAplicados"
        :agrupamento="filtrosAplicados.agrupamento"
        ref="tabelaTodosContratos"
        @acao_aprovada="aplicaFiltros(null, true)"
        />
    </v-card>

    <div class="text-xs-center mt-3" v-if="totalPagina > 1 && !exibirTodosContratos">
      <v-pagination
        v-model="pagina"
        :length="totalPagina"
        :total-visible="5"
        @input="aplicaFiltros(null)"
      ></v-pagination>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import PlanejamentoContratoListDadosTabela from '@/spa/planejamento-contrato/list/PlanejamentoContratoListDadosTabela';
import PlanejamentoContratoListDadosAgrupamento from '@/spa/planejamento-contrato/list/PlanejamentoContratoListDadosAgrupamento';
import {
  buscarTiposContrato,
} from '@/common/resources/planejamento-contrato-listagem';
import { forceLoading, forceHideLoading } from '@/common/functions/loading';

export default {
  name: 'PlanejamentoContratoListDados',
  components: {
    PlanejamentoContratoListDadosTabela,
    PlanejamentoContratoListDadosAgrupamento,
  },
  props: {
    tipoContrato: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    ...mapGetters('implantacao', [
      'chaveEmpresa',
    ]),
    canAlterarVencimentoContrato() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el === 'ALTERAR_VENCIMENTO_CONTRATO').length;
    },
    isAmbienteEpoca() {
      return this.chaveEmpresa === 'EPOCA';
    },
  },
  data() {
    return {
      configuracaoResource: this.$api.planejamentoContratoConfiguracao(this.$resource),

      tiposContrato: [],
      painel: null,
      pagina: 1,
      totalPagina: 1,
      quantidadePagina: 15,
      iniciandoPainel: false,
      exibirTodosContratos: false,

      filtrosAplicados: {},
      parametrizacaoContrato: {},
      tipoContratoTodos: {
        nome: 'Todos',
        indAtivo: true,
        contratos: [],
      },
      camposTodosTiposContratos: [],
    };
  },
  methods: {
    async carregaParametrosTiposContrato(tiposContrato) {
      const listaIdsTipoContrato = [...new Set(tiposContrato.map((tipo) => tipo.id))];
      await Promise.all(listaIdsTipoContrato
        .map((id) => this.configuracaoResource
          .buscarCamposGrid({ idTipoContrato: id })
          .then((res) => {
            const campos = res.data;
            const camposDinamicos = this.camposDinamicos();
            if (!this.exibirTodosContratos) {
              camposDinamicos.splice(0, 1);
            }
            this.parametrizacaoContrato[id] = [...camposDinamicos, ...campos];
            if (this.exibirTodosContratos) {
              this.setaCamposTodosTiposContratos();
            }
          })));
    },
    tituloDoPainel(tipoContrato) {
      let titulo = `${this.$t('label.tipo_contrato')} :: ${tipoContrato.nome}`;
      if (tipoContrato.agrupamento) {
        const { tipoAgrupamento, agrupamento } = tipoContrato;
        let labelTipo;
        if (['fornecedor'].indexOf(tipoAgrupamento) >= 0) {
          labelTipo = this.$tc(`label.${tipoAgrupamento}`, 1);
        } else {
          labelTipo = tipoAgrupamento;
        }
        titulo += ` :: ${labelTipo} :: ${agrupamento}`;
      }
      return titulo;
    },
    aplicaFiltros(filtros, indManterFiltrosAplicados = false) {
      this.tiposContrato = null;
      this.painel = null;
      const agrupamento = this.$refs.agrupamento.agrupamentoHabilitado();
      const { indiceLabelAgrupamento } = this.$refs.agrupamento;

      if (!indManterFiltrosAplicados) {
        this.filtrosAplicados = {
          ...filtros,
          numeroPagina: filtros ? 1 : this.pagina,
          tamanhoPagina: this.quantidadePagina,
          indice_label_agrupamento: indiceLabelAgrupamento,
          agrupamento,
        };
      }
      forceLoading();
      buscarTiposContrato(this.filtrosAplicados, this.$resource)
        .then((res) => {
          const tiposContrato = res.data.resposta;
          this.totalPagina = Math.ceil(res.data.quantidadeRegistrosPagina / this.quantidadePagina);
          this.carregaParametrosTiposContrato(tiposContrato)
            .then(() => {
              this.tiposContrato = tiposContrato;
              this.iniciandoPainel = false;
              if (indManterFiltrosAplicados && this.exibirTodosContratos) {
                this.$refs.tabelaTodosContratos.buscarContratos();
              } else {
                forceHideLoading();
              }
            });
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    alterarAgrupamento(agrupamento = null) {
      this.pagina = 1;
      if (agrupamento === 'todos') {
        this.exibirTodosContratos = true;
      } else {
        this.exibirTodosContratos = false;
      }
      this.$emit('PlanejamentoContratoDados__AplicaFiltros');
    },
    redirecionarAlteracaoPrazoVencimento() {
      this.$router.push({ name: 'alterarPrazoVencimento' });
    },
    setaCamposTodosTiposContratos() {
      Object.keys(this.parametrizacaoContrato).forEach((k) => {
        this.parametrizacaoContrato[k].forEach((campo) => {
          if (!this.camposTodosTiposContratos.includes(campo)) {
            this.camposTodosTiposContratos.push(campo);
          }
        });
      });
    },
    camposDinamicos() {
      const colunasDinamicas = ['tipo_contrato'];
      if (!this.isAmbienteEpoca) {
        colunasDinamicas.push('pendencia_renovacao');
      }
      return colunasDinamicas;
    },
  },
  mounted() {
    this.$refs.agrupamento.agrupamentoPadraoPorTipo();
  },
};
</script>

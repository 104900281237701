<template>
  <v-dialog v-model="dialog" :persistent="false" :max-width="1000" :min-width="500">
    <v-card>
      <v-card-title class="headline">{{ $tc('label.categoria', 1) }}</v-card-title>
      <v-card-text  class="justify" style="padding: 0px 9px" >
        <v-list-item v-for="categoriaAtiva in categoriasAtivas" :key="categoriaAtiva">
          <v-list-item-content>
            <v-list-item-title>{{ categoriaAtiva }}</v-list-item-title>
          </v-list-item-content>
         </v-list-item>
        <v-list-item v-for="categoriaInativa in categoriasInativas" :key="categoriaInativa">
          <v-list-item-content>
            <v-list-item-title>{{ categoriaInativa }}</v-list-item-title>
          </v-list-item-content>
         </v-list-item>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn id="sim" color="primary darken-1" text @click.native="agree">
          {{$t('label.ok')}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DialogCategoriaContrato',
  props: {
  },
  data() {
    return {
      planejamentoContratoListagemResource: this.$api.planejamentoContratoListagem(this.$resource),
      titleDialog: '',
      categoriasAtivas: [],
      categoriasInativas: [],
      dialog: false,
      callback: null,
    };
  },
  methods: {
    open(idContrato) {
      this.dialog = true;
      setTimeout(() => this.buscarCategoriasContrato(idContrato));
    },
    buscarCategoriasContrato(idContrato) {
      this.planejamentoContratoListagemResource.buscarCategoriasContrato({ idContrato })
        .then((res) => {
          this.categoriasAtivas = res.data.categorias_ativas ? res.data.categorias_ativas.split(' | ') : [];
          this.categoriasInativas = res.data.categorias_inativas ? res.data.categorias_inativas.split(' | ') : [];
        }).catch((err) => {
          this.$error(this, err);
        });
    },
    agree() {
      this.dialog = false;
    },
  },
};
</script>
<style lang="scss">
.justify {
  text-align: justify;
}
</style>

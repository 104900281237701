<style>
.PlanejamentoContratoList {
  padding-bottom: 60px;
}
.PlanejamentoContratoList__Modal .headline {
  width: 100%;
  text-align: center;
}
.PlanejamentoContratoList__Lista {
  margin-top: 20px;
}
</style>
<template>
  <div class="PlanejamentoContratoList">
    <planejamento-contrato-list-filtros ref="filtros"
      v-if="verificouQtdeTipos"
      :contratoDuplicadoParaFiltro="contratoDuplicadoParaFiltro"
      :idContratoParaFiltro="parseInt(idContratoParaFiltro)"
      @PlanejamentoContratoFiltros__AplicaFiltros="aplicaFiltros"
    />

    <planejamento-contrato-list-cards
      ref="cards" class="mb-3"
      v-if="controleLayout.exibirCards"/>

    <planejamento-contrato-list-dados ref="dados"
      v-if="verificouQtdeTipos"
      :tipo-contrato="tipoContrato"
      @PlanejamentoContratoDados__AplicaFiltros="alterarAgrupamento"
    />

    <v-btn fab
      id="adicionar_contrato"
      bottom right fixed
      class="v-btn__fab"
      color="primary"
      @click.native="criarNovoContrato()"
      v-show="canEdit">
      <v-icon>add</v-icon>
    </v-btn>

    <v-dialog v-model="exibeModalNovoContrato" max-width="350">
      <v-card class="PlanejamentoContratoList__Modal">
        <v-card-title>
          <span class="headline">
            {{ $t('message.selecione_tipo_contrato') }}
          </span>
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="filtroTipo"
            @input="page = 1; buscarTiposContrato(filtroTipo)"
            :label="$t('label.filtrar')"
            clearable
            append-icon="search">
          </v-text-field>
          <v-list class="PlanejamentoContratoList__Lista">
            <v-list-item @click="criarContrato(tipo)"
              v-for="(tipo, index) in tiposContrato"
              :key="index"
              class="PlanejamentoAcaoList__TipoAcao"
              ripple>
              <v-list-item-content>
                <v-list-item-title class="primary--text">{{tipo.nome}}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon color="primary">arrow_forward</v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <div class="text-xs-center">
            <v-pagination
              v-model="page"
              v-if="quantidadePagina > 1"
              :length="quantidadePagina"
              :total-visible="5"
            ></v-pagination>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="accent" text @click.native="cancelarCriacaoContrato">{{ $t('label.cancelar') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import PlanejamentoContraloControleLayout from '@/spa/planejamento-contrato/list/PlanejamentoContraloControleLayout';
import { generateComputed } from '@/common/functions/roles-computed-generator';

import PlanejamentoContratoListFiltros from '@/spa/planejamento-contrato/list/PlanejamentoContratoListFiltros';
import PlanejamentoContratoListDados from '@/spa/planejamento-contrato/list/PlanejamentoContratoListDados';
import PlanejamentoContratoListCards from './PlanejamentoContratoListCards';

export default {
  mixins: [PlanejamentoContraloControleLayout],
  components: {
    PlanejamentoContratoListFiltros,
    PlanejamentoContratoListCards,
    PlanejamentoContratoListDados,
  },
  computed: {
    ...generateComputed('CONTRATO', [
      'canAccessPage',
      'canEdit',
    ]),
    ...generateComputed('ACESSO_CLIENTE', [
      'getAllRoles',
    ]),
    indPodeCadastrarContratoSamsung() {
      return !!this.getAllRoles.filter((el) => el.startsWith('CONTRATO_SAMSUNG_CRUD')).length
        && this.canEdit;
    },
  },
  data() {
    return {
      planejamentoContratoResource: this.$api.planejamentoContratoCadastro(this.$resource),
      planejamentoContratoListagemResource: this.$api.planejamentoContratoListagem(this.$resource),

      verificouQtdeTipos: false,
      unicoTipoContrato: false,
      tipoContrato: null,

      exibeModalNovoContrato: false,
      filtroTipo: null,
      filtros: {},
      tiposContrato: [],
      page: 1,
      quantidadePagina: 1,
      contratoDuplicadoParaFiltro: false,
      idContratoParaFiltro: null,
    };
  },
  watch: {
    page() {
      this.buscarTiposContrato(this.filtroTipo);
    },
  },
  methods: {
    accessForbidden() {
      if (!this.canAccessPage) {
        if (!this.getAllRoles
            || !this.getAllRoles.filter((el) => el.startsWith('ACESSO_CLIENTE')).length) {
          this.$router.push({ name: 'inicio' });
          this.$toast(this.$t('message.acesso_negado'));
        }
      }
    },
    criarContrato(tipoContrato) {
      this.exibeModalNovoContrato = false;
      const idTipoContrato = tipoContrato.id;
      this.$router.push({ name: 'novoContrato', params: { idTipoContrato } });
    },
    criarNovoContrato() {
      this.buscarTiposContrato(this.filtroTipo);
    },
    buscarTiposContrato(nome = '') {
      const { indPodeCadastrarContratoSamsung } = this;
      this.planejamentoContratoResource.buscarTiposContrato({ nome, indPodeCadastrarContratoSamsung })
        .then((res) => {
          this.tiposContrato = res.data;
          if (this.tiposContrato.length === 1) {
            const unicoTipo = this.tiposContrato[0];
            this.criarContrato(unicoTipo);
            return;
          }
          this.exibeModalNovoContrato = true;
          this.quantidadePagina = Math.ceil(res.data.quantidadeRegistrosPagina / 10);
        }).catch((err) => {
          this.$error(this, err);
        });
    },
    cancelarCriacaoContrato() {
      this.exibeModalNovoContrato = false;
      this.tiposContrato = [];
      this.filtroTipo = null;
    },
    aplicaFiltros(filtros = {}) {
      if (this.contratoDuplicadoParaFiltro) {
        filtros.id_contrato = this.idContratoParaFiltro;
        this.contratoDuplicadoParaFiltro = false;
        localStorage.removeItem('idContratoDuplicidade');
      }
      this.filtros = filtros;
      this.$refs.dados.aplicaFiltros(filtros);
      if (this.controleLayout.exibirCards) {
        this.$refs.cards.aplicaFiltros(filtros);
      }
      this.idContratoParaFiltro = null;
    },
    verificaECarregaUnicoTipoContrato() {
      this.planejamentoContratoListagemResource
        .vericaUnicoTipo()
        .then((res) => {
          this.unicoTipoContrato = res.data;
        })
        .then(() => {
          if (this.unicoTipoContrato) {
            return this.planejamentoContratoResource
              .buscarTiposContrato()
              .then((res) => {
                const tipos = res.data;
                const [tipo] = tipos;
                this.tipoContrato = tipo;
              });
          }
          return true;
        })
        .finally(() => {
          this.verificouQtdeTipos = true;
        });
    },
    alterarAgrupamento() {
      this.$refs.dados.aplicaFiltros(this.filtros);
    },
  },
  mounted() {
    if (localStorage.getItem('idContratoDuplicidade')) {
      this.contratoDuplicadoParaFiltro = true;
      this.idContratoParaFiltro = localStorage.getItem('idContratoDuplicidade');
    }
    if (!this.canAccessPage) {
      window.setTimeout(() => this.accessForbidden(), 1E3);
    }
    this.verificaECarregaUnicoTipoContrato();
  },
};
</script>

<template>
  <v-dialog v-model="showModal" @keydown.esc="cancela" @click:outside="cancela" :max-width="indSubstituicaoContrato && isAprovacao? '1500px' : '800px'">
    <v-form ref="form" lazy-validation>
      <v-card>
        <div v-if="!acaoPossuiPassoFornecedor">
          <v-alert type="warning"
                   elevation="2"
                   class="pa-2"
          >
            {{ this.$t('message.acao_sem_aprovacao_fornecedor') }}
          </v-alert>
        </div>
        <v-card-title v-if="tituloModal">
          <span class="headline">{{ tituloModal }}</span>
        </v-card-title>
        <v-card-title v-if="!isAprovacao && !tituloModal">
          <span class="headline"> {{ $tc('label.justificativa', 1) }}</span>
        </v-card-title>
        <v-card-title v-if="isAprovacao && !tituloModal">
          <span class="headline">{{ $tc('label.aprovar', 1) }}</span>
        </v-card-title>
        <v-card-text>
          <v-container class="grey lighten-5">
            <v-row>
              <v-col cols="12" :sm="indSubstituicaoContrato && isAprovacao ? '6' : '12'">
                <v-autocomplete
                  v-if="!!idAcao && podeAlterarTipoDeUso"
                  id="justificativa-tipo-uso"
                  class="custom-autocomplete"
                  v-model="tipoUso"
                  :rules="[rules.requiredAutocomplete]"
                  :items="listaTiposUso"
                  :label="`${$tc('label.tipo_uso', 1)} *`"
                  :no-data-text="$tc('message.nenhum_registro', 1)"
                  item-text="nome"
                  item-value="id"
                  required
                  :disabled="somenteLeitura"
                  :clearable="true"
                  @click:append="() => triggerSelecao('justificativa-tipo-uso')"
                  @click.native="(i) => buscaAutocomplete(i, buscarTiposUso)"
                ></v-autocomplete>
                <v-text-field
                  v-if="isContrato && usuarioAprovadorContratoComplementoInformacoes"
                  :label="$tc('label.prazo_pagamento_dias', 1)"
                  v-model="prazoPagamento"
                  type="Number"
                  min="1"
                  :hint="$tc('label.prazo_pagamento_padrao', 1)"
                  :persistent-hint="true"
                ></v-text-field>
                <v-menu
                  v-if="podeAlterarDataContabilizacao && !!idAcao"
                  ref="data_contabilizacao"
                  :close-on-content-click="true"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  full-width
                  max-width="290px"
                  min-width="290px">
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-on="on"
                      id="data_contabilizacao"
                      class="CalendarioForm-data__text"
                      readonly
                      clearable
                      :rules="[rules.required]"
                      v-model="dataContabilizacaoFormatada"
                      :label="`${$t('label.data_contabilizacao')} *`"
                      prepend-icon="event">
                    </v-text-field>
                  </template>
                  <v-date-picker
                    locale="pt-br"
                    color="primary"
                    scrollable
                    @input="setaDataFormatada()"
                    v-model="dataContabilizacao">
                  </v-date-picker>
                </v-menu>
                <span v-if="isContrato && isUsuarioFornecedor && isAprovacao">
                  {{ this.$t('message.fornecedor_aprovador_ler_carta_parceria') }}
                </span>
                <v-textarea
                  id="justificativa"
                  v-if="!obrigatorio && !somenteLeitura"
                  name="justificativa"
                  :label="`${$tc('label.justificativa', 1)}`"
                  v-model="justificativa"
                  auto-grow
                  maxlength="150"
                  autofocus
                  rows="1"
                  :disabled="somenteLeitura"
                ></v-textarea>
                <v-textarea
                  id="justificativa_obrigatoria"
                  v-if="obrigatorio && !somenteLeitura"
                  name="justificativa-obrigatoria"
                  :label="`${$tc('label.justificativa', 1)} *`"
                  v-model="justificativa"
                  auto-grow
                  autofocus
                  maxlength="150"
                  rows="1"
                  :rules="[rules.required]"
                  :disabled="somenteLeitura"
                ></v-textarea>
                <v-textarea
                  v-if="somenteLeitura"
                  name="observacao"
                  :label="`${$tc('label.justificativa', 1)}`"
                  :value="observacao"
                  auto-grow
                  rows="1"
                  maxlength="150"
                  :disabled="somenteLeitura"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
          <div v-if="isContrato && isCartaParceria && isAprovacao">
            <v-container>
              <v-row>
                <v-col cols="12" sm="12">
                  <div>
                    <span>{{ this.$t('message.aprovar_carta_parceria_lista_documentos', datasFormatadas) }}</span>
                    <v-tooltip bottom v-if="indSubstituicaoContrato">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="grey darken-3" dark v-bind="attrs" v-on="on">
                          info
                        </v-icon>
                      </template>
                      <v-data-table
                      :headers="headers"
                      hide-default-header
                      hide-default-footer
                      disable-pagination
                      :items-per-page="-1"
                      :items="listaDocumentosGerados"
                      :no-data-text="$t('label.tabela_sem_conteudo')">
                        <template v-slot:[`item.nome_arquivo`]="{ item }">
                          {{ item.nomeArquivo }}
                        </template>
                      </v-data-table>
                    </v-tooltip>
                  </div>
                  <div :align="indSubstituicaoContrato ? 'start' : 'center'" class="py-2">
                    <v-btn
                      class="mb-2"
                      color="primary"
                      @click.native="realizarDownloadCartaParceria()">
                      {{ $tc('label.download', 1) }}
                    </v-btn>
                  </div>
                  <v-data-table
                    v-if="!indSubstituicaoContrato"
                    :headers="headers"
                    :items="listaDocumentosGerados"
                    :no-data-text="$t('label.tabela_sem_conteudo')">
                    <template v-slot:[`item.nome_arquivo`]="{ item }">
                      {{ item.nomeArquivo }}
                    </template>
                  </v-data-table>
                  <div v-if="indSubstituicaoContrato && listaCategoriasAprovacao.length">
                    <h3 class="red--text font-weight-bold">
                      {{ $tc('title.atencao_alerta', 1) }}
                    </h3>
                    <p class="mb-0 font-weight-regular">
                      {{ $tc('message.contratos_em_cancelamento', 1) }}
                    </p>
                    <p class="font-weight-regular">
                      {{ $tc('message.contratos_em_cancelamento_verificacao', 1) }}
                    </p>
                  </div>
                  <v-container v-if="indSubstituicaoContrato && listaCategoriasAprovacao.length">
                    <v-row no-gutters>
                      <v-col cols="3">
                        <p class="font-weight-black m-0 p-0">
                          {{ $tc('message.categoria_contrato_aprovacao', 2) }}
                        </p>
                      </v-col>
                      <v-col cols="3">
                        <v-data-table
                          dense
                          :headers="headerCategoria"
                          hide-default-header
                          hide-default-footer
                          disable-pagination
                          class="m-0 p-0"
                          :items="listaCategoriasAprovacao">
                          <template class="m-0 p-0" v-slot:[`item.categoria`]="{ item }">
                            {{ item.categoria }}
                          </template>
                        </v-data-table>
                      </v-col>
                    </v-row>
                  </v-container>
                  <v-data-table
                    v-if="indSubstituicaoContrato && contratosRenovacao.length"
                    :headers="headerRenovacaoContrato"
                    :items="contratosRenovacao"
                    :no-data-text="$t('label.tabela_sem_contrato_substituicao')"
                    >
                    <template v-slot:[`item.origem_contrato`]="{ item }">
                      {{ item.origem_contrato }}
                    </template>
                    <template v-slot:[`item.data_inicio`]="{ item }">
                      {{ item.data_inicio }}
                    </template>
                    <template v-slot:[`item.data_fim`]="{ item }">
                      {{ item.data_fim }}
                    </template>
                    <template v-slot:[`item.categoria`]="{ item }">
                      {{ item.categoria }}
                    </template>
                    <template v-slot:[`item.status`]="{ item }">
                      {{ item.status }}
                    </template>
                    <template v-slot:[`item.tipo_contrato`]="{ item }">
                      {{ item.tipo_contrato }}
                    </template>
                    <template v-slot:[`item.detalhar`]="{ item }">
                      <v-btn small depressed color="primary" @click="abrirDetalhes(item)">
                      {{ $tc('label.detalhe', 2) }}
                      </v-btn>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-container>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn v-if="somenteLeitura" @click.native="cancela">{{ $t('label.fechar') }}</v-btn>
          <v-btn v-if="!somenteLeitura" @click.native="cancela">{{ $t('label.cancelar') }}</v-btn>
          <v-btn
            v-if="!somenteLeitura"
            color="primary darken-1"
            @click.native="salvar"
          >{{ $t('label.salvar') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import moment from 'moment';
import {
  formatDateDDMMYYYYBarras,
} from '@/common/functions/date-utils';
import { mapGetters } from 'vuex';
import {
  buscaAutocomplete,
  triggerSelecao,
} from '@/common/functions/autocomplete-utils';
import exportacaoCartaParceria from '@/common/functions/downloads';
import basePath from '@/common/functions/api-resource';

export default {
  data() {
    return {
      workflowAcaoMagaluResource: this.$api.workflowAcaoMagalu(this.$resource),
      workflowContratoResource: this.$api.workflowContrato(this.$resource),
      planejamentoContratoResource: this.$api.planejamentoContratoCadastro(this.$resource),

      showModal: false,
      justificativa: '',
      tipoUso: null,
      prazoPagamento: null,
      listaTiposUso: [],
      usuarioAprovadorNDComplementoInformacoes: false,
      usuarioAprovadorContratoComplementoInformacoes: false,
      dataContabilizacaoFormatada: null,
      dataContabilizacao: new Date().toJSON().substr(0, 10),
      acaoPossuiPassoFornecedor: true,
      listaCategoriasAprovacao: [],
      rules: {
        required: (value) => (!!value && value.trim().length > 0) || this.$t('message.campo_obrigatorio'),
        requiredAutocomplete: (value) => (!!value) || this.$t('message.campo_obrigatorio'),
      },
      contratosRenovacao: [],
      idPassoAtual: 0,

      listaDocumentosGerados: [],
      totalPage: 0,
      pagination: {
        itemsPerPage: 10,
        page: 1,
      },
      headers: [
        {
          text: this.$tc('label.nome_arquivo', 1), value: 'nomeArquivo', width: '100%', align: 'center', sortable: false,
        },
      ],
      headerCategoria: [
        {
          text: this.$tc('label.categoria', 1), value: 'categoria', width: '50%', align: 'left', sortable: false,
        },
      ],
      headerRenovacaoContrato: [
        {
          text: '', value: 'checkbox', width: '1%', sortable: false,
        },
        {
          text: this.$tc('label.id', 1), value: 'id_contrato', width: '1%', align: 'center', sortable: false,
        },
        {
          text: this.$tc('label.origem_contrato', 1), value: 'origem_contrato', width: '12%', align: 'center', sortable: false,
        },
        {
          text: this.$tc('label.data_inicio', 1), value: 'data_inicio', width: '6%', align: 'center', sortable: false,
        },
        {
          text: this.$tc('label.data_fim', 1), value: 'data_fim', width: '5%', align: 'center', sortable: false,
        },
        {
          text: this.$tc('label.categoria', 1), value: 'categoria', width: '10%', align: 'center', sortable: false,
        },
        {
          text: this.$tc('label.status', 1), value: 'status', width: '5%', align: 'center', sortable: false,
        },
        {
          text: this.$tc('label.tipo_contrato', 1), value: 'tipo_contrato', width: '10%', align: 'center', sortable: false,
        },
        {
          text: '', value: 'detalhar', width: '2%', sortable: false,
        },
      ],
    };
  },
  props: {
    obrigatorio: Boolean,
    salvarJustificativa: Function,
    somenteLeitura: {
      type: Boolean,
      default: false,
    },
    observacao: String,
    idAcao: Number,
    idContrato: Number,
    isAprovacao: {
      type: Boolean,
      default: false,
    },
    isContrato: {
      type: Boolean,
      default: false,
    },
    isCartaParceria: {
      type: Boolean,
      default: false,
    },
    indSubstituicaoContrato: {
      type: Boolean,
      default: false,
    },
    isModalidadeValorFixo: {
      type: Boolean,
      default: false,
    },
    dataInicioContrato: String,
    dataFimContrato: String,
    tituloModal: String,
  },
  watch: {
    idAcao() {
      this.formataCampos();
    },
    isAprovacao() {
      this.formataCampos();
      if (this.idContrato && this.isCartaParceria && this.isAprovacao) {
        this.carregaListaDocumentosGerados();
      }
    },
    idContrato() {
      if (this.idContrato && this.isAprovacao) {
        this.verificaSeUsuarioAprovadorContratoComplementoInformacoes();
        if (this.isCartaParceria) {
          this.carregaListaDocumentosGerados();
        }
      }
    },
  },
  computed: {
    ...mapGetters('usuario', [
      'usuarioLogado',
    ]),
    isUsuarioFornecedor() {
      return this.usuarioLogado.tipo === 'FORNECEDOR';
    },
    dataInicioFormatada() {
      if (!this.dataInicioContrato) return null;
      return formatDateDDMMYYYYBarras(this.dataInicioContrato);
    },
    dataFimFormatada() {
      if (!this.dataFimContrato) return null;
      return formatDateDDMMYYYYBarras(this.dataFimContrato);
    },
    datasFormatadas() {
      if (!this.dataInicioContrato || !this.dataFimContrato) return null;
      return {
        dataInicio: this.dataInicioFormatada,
        dataFim: this.dataFimFormatada,
      };
    },
    podeAlterarTipoDeUso() {
      return this.usuarioAprovadorNDComplementoInformacoes && !this.obrigatorio;
    },
    podeAlterarDataContabilizacao() {
      return this.usuarioAprovadorNDComplementoInformacoes;
    },
  },
  methods: {
    buscaAutocomplete,
    triggerSelecao,
    open() {
      this.justificativa = '';
      this.contratosRenovacao = [];
      if (this.indSubstituicaoContrato) {
        this.buscarContratosRenovacao();
      } else {
        this.showModal = true;
      }
    },
    cancela() {
      this.showModal = false;
      this.$emit('PlanejamentoAcaoJustificativa__Cancelar');
    },
    salvar() {
      if (!this.$refs.form.validate()) return;
      const promises = [];
      if (this.isAprovacao) {
        promises.push(this.salvarDataContabilizacao());
        promises.push(this.salvarComplementoInformacoes());
        if (this.contratosRenovacao.length) {
          promises.push(this.alterarContratoSubstituicaoAguardandoEncerramento());
        }
      }
      Promise.all(promises)
        .then(() => {
          this.salvarJustificativa(this.justificativa);
          this.salvarTipoUso();
        })
        .catch((err) => {
          this.$error(this, err);
        });
      this.showModal = false;
    },
    alterarContratoSubstituicaoAguardandoEncerramento() {
      const promises = [];
      this.contratosRenovacao.forEach((contrato) => {
        const param = {
          idContratoAtual: this.idContrato,
          idContratoEncerramento: contrato.id_contrato,
        };
        const promise = this.planejamentoContratoResource.alterarContratoSubstituicaoAguardandoEncerramento(param, param)
          .then(() => {})
          .catch((err) => {
            this.$toast(err.data.error);
          });
        promises.push(promise);
      });
      Promise.all(promises);
    },
    carregarTipoUsoDaAcao() {
      const { idAcao } = this;
      this.workflowAcaoMagaluResource
        .carregarTipoUso({ idAcao })
        .then((res) => {
          this.tipoUso = { id: res.data.id, nome: res.data.nome };
          const tipoUso = this.listaTiposUso.find((tipo) => tipo.nome === this.tipoUso.nome);
          if (tipoUso) {
            tipoUso.id = this.tipoUso.id;
          }
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    buscarTiposUso() {
      return this.workflowAcaoMagaluResource
        .buscarTiposUso()
        .then((res) => res)
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    salvarTipoUso() {
      if (this.tipoUso) {
        const { idAcao } = this;
        let { tipoUso } = this;
        tipoUso = {
          ...tipoUso,
          justificativa_tipo_uso: this.justificativa || null,
          tipo_uso: tipoUso.id,
        };
        this.workflowAcaoMagaluResource.salvarAcaoTipoUso({ idAcao }, tipoUso)
          .catch((err) => {
            this.$error(this, err);
          });
      }
    },
    verificaSeUsuarioAprovadorNDComplementoInformacoes() {
      const params = {
        idAcao: this.idAcao,
        idPerfil: this.usuarioLogado.idPerfil,
      };
      this.workflowAcaoMagaluResource
        .verificarSeUsuarioAprovadorNDComplementoInformacoes(params)
        .then((res) => {
          this.usuarioAprovadorNDComplementoInformacoes = res.data;
          if (this.podeAlterarDataContabilizacao) {
            this.setaDataFormatada();
          }
          if (this.usuarioAprovadorNDComplementoInformacoes) {
            Promise.all([this.buscarTiposUso()])
              .then((response) => {
                const [retorno] = response;
                this.listaTiposUso = retorno.data;
                this.carregarTipoUsoDaAcao();
              });
          }
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    salvarDataContabilizacao() {
      if (this.podeAlterarDataContabilizacao && this.dataContabilizacao) {
        const { idAcao } = this,
          { dataContabilizacao } = this;
        this.workflowAcaoMagaluResource.salvarAcaoDataContabilizacao({ idAcao },
          { data_contabilizacao: dataContabilizacao })
          .then(() => {})
          .catch((err) => {
            this.$error(this, err);
          });
      }
    },
    setaDataFormatada() {
      this.dataContabilizacaoFormatada = this.retornaDataFormatada(this.dataContabilizacao);
    },
    retornaDataFormatada(data) {
      if (!data) return null;
      return moment(data, 'YYYY-MM-DD').format('DD/MM/YYYY');
    },
    verificaSePossuiPassoFornecedor() {
      const { idAcao } = this;
      this.workflowAcaoMagaluResource
        .verificarSePossuiPassoFornecedor({ idAcao })
        .then((res) => {
          this.acaoPossuiPassoFornecedor = res.data;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    formataCampos() {
      if (this.idAcao && this.isAprovacao) {
        this.verificaSeUsuarioAprovadorNDComplementoInformacoes();
        this.verificaSePossuiPassoFornecedor();
      } else {
        this.usuarioAprovadorNDComplementoInformacoes = false;
        this.acaoPossuiPassoFornecedor = true;
      }
      this.resetaCampos();
    },
    resetaCampos() {
      this.justificativa = '';
      this.tipoUso = null;
      this.prazoPagamento = null;
      this.listaTiposUso = [];
      this.contratosRenovacao = [];
      this.idPassoAtual = 0;
      this.listaDocumentosGerados = [];
    },
    carregaListaDocumentosGerados() {
      const params = {
        idContrato: this.idContrato,
      };
      this.planejamentoContratoResource.buscarListaDocumentosGerados(params)
        .then((res) => {
          if (res.data && res.data.length) {
            this.listaDocumentosGerados = res.data;
          }
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    realizarDownloadCartaParceria() {
      const getBasePath = basePath('job', 'contrato/carta-parceria');
      const servico = 'gerar-carta-parceria';
      const resource = exportacaoCartaParceria(this.$http);
      const { idContrato } = this;
      resource.download(getBasePath, idContrato, servico)
        .then(() => {
          this.$toast(this.$t('message.carta_parceria_gerada_sucesso'));
        })
        .catch(() => {
          this.$toast(this.$t('message.carta_parceria_gerada_erro'));
        });
    },
    verificaSeUsuarioAprovadorContratoComplementoInformacoes() {
      const params = {
        idContrato: this.idContrato,
        idPerfil: this.usuarioLogado.idPerfil,
      };
      this.workflowContratoResource
        .verificaSeUsuarioAprovadorContratoComplementoInformacoes(params)
        .then((res) => {
          this.usuarioAprovadorContratoComplementoInformacoes = res.data;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    salvarComplementoInformacoes() {
      if (this.usuarioAprovadorContratoComplementoInformacoes) {
        this.salvarPrazoPagamento();
      }
    },
    salvarPrazoPagamento() {
      if (!this.prazoPagamento) {
        this.prazoPagamento = 30;
      }

      const { idContrato, prazoPagamento } = this;
      this.workflowContratoResource.salvarPrazoPagamento({ idContrato },
        { prazo_pag_contrato: prazoPagamento })
        .then(() => {})
        .catch((err) => {
          this.$error(this, err);
        });
    },
    abrirDetalhes(item) {
      const route = this.$router.resolve({ name: 'detalharContrato', params: { idContrato: item.id_contrato, from: 'dashboard-inicial', carregaPassoAcoes: true } });
      window.open(route.href, '_blank');
    },
    buscarContratosRenovacao() {
      const { idContrato } = this;
      this.workflowContratoResource.buscarContratosRenovacao({ idContrato }).then((res) => {
        this.contratosRenovacao = res.data;
        this.listaCategoriasAprovacao = [];
        this.contratosRenovacao.forEach((contrato) => {
          if (!this.listaCategoriasAprovacao.find((contratoListaCategoria) => contratoListaCategoria.categoria === contrato.categoria)) {
            this.listaCategoriasAprovacao.push(contrato);
          }
        });
        this.showModal = true;
      });
    },
  },
  mounted() {
    if (this.idContrato) {
      this.verificaSeUsuarioAprovadorContratoComplementoInformacoes();
    }
  },
};
</script>
